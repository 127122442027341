<template>
  <Page :classes="['page--vcenter']">

    <transition name="fade" mode="out-in">
      <form class="auth" v-if="!isRegistered" v-on:submit.prevent="sendRegisterRequest">
        <div class="auth__header">
          <h1 class="auth__title">{{$t('auth.create_account')}}</h1>
        </div>

        <InputEmail
          name="email"
          :label="$t('common.email')"
          v-model.trim="email"
          :focusOnMount="true"
          :isRequired="true"
        ></InputEmail>

        <InputPassword
          name="password"
          :label="$t('common.password')"
          v-model.trim="password"
          :isRequired="true">
        </InputPassword>

        <InputPassword
          name="password_repeat"
          :label="$t('auth.password_repeat')"
          v-model.trim="password2"
          :isRequired="true">
          <span v-if="passwordsDontMatch">
            {{$t('auth.passwords_dont_match')}}
          </span>
        </InputPassword>

        <InputCheckbox
          name="tos"
          v-model="isTosAccepted"
          :isRequired="true">
          <template #header>
            <span>
              {{$t('auth.accept_tos_start')}} <router-link :to="{name: 'tos'}">
                {{$t('common.tos')}}
              </router-link> {{$t('auth.accept_tos_end')}}
            </span>
          </template>
          </InputCheckbox>

        <Button
          :isLoading="isLoading"
          :isDisabled="isInvalid"
          :classes="['btn--accent', 'btn--wide']"
          @onclick="sendRegisterRequest()">
            <span v-show="!isLoading">{{$t('auth.create_account')}}</span>
            <span v-show="isLoading">{{$t('auth.creating_account')}}</span>
        </Button>

        <p class="auth__link">
          {{$t('auth.already_registered')}}
          <router-link :to="{name: 'login', params: { locale: $i18n.locale }}">
            {{$t('auth.login')}}
          </router-link>
        </p>

      </form>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if="isRegistered" class="auth">
        <div class="auth__header">
          <h1 class="auth__title">{{$t('auth.account_registered')}}</h1>
        </div>
        <p class="auth__tip">{{$t('auth.account_registered_tip')}}</p>

        <p class="auth__link">
          <router-link :to="{name: 'login', params: { locale: $i18n.locale }}">
            {{$t('common.back_to_home')}}
          </router-link>
        </p>
      </div>
    </transition>

  </Page>
</template>

<script>
import Check from '@/services/check/Check';
import InputEmail from '@/components/inputs/email/InputEmail.vue';
import InputPassword from '@/components/inputs/password/InputPassword.vue';

export default {
  name: 'Register',
  components: {
    InputEmail,
    InputPassword,
  },
  data() {
    return {
      email: '',
      password: '',
      password2: '',
      isTosAccepted: false,
      isLoading: false,
      isRegistered: false,
    };
  },
  computed: {
    isInvalid() {
      return !Check.isValidEmail(this.email)
        || !Check.isValidPassword(this.password)
        || this.password !== this.password2
        || !this.isTosAccepted;
    },
    passwordsDontMatch() {
      return Check.isValidPassword(this.password2)
        && this.password !== this.password2;
    },
  },
  methods: {
    sendRegisterRequest() {
      this.isLoading = true;

      this.$store.dispatch('registerUser', {
        email: this.email,
        password: this.password2,
      }).then(() => {
        this.isRegistered = true;
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
